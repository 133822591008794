import { useContext, useState } from 'react'
import type { Brand } from '../../../../graphqlTypes'
import { classNames } from '../../../../helpers/classNames'
import { FeatureContext } from '../../../Shared/Hooks/FeatureToggle'
import { useTracking } from '../../../Shared/Hooks/TrackingHook'
import { useAfterMountEffect } from '../../../Shared/Hooks/useAfterMountEffect'
import { asset } from '../../../Shared/asset'
import { GwmBrandSelect } from '../../Components/Gwm/GwmBrandSelect/GwmBrandSelect'
import { GwmModelSelect } from '../../Components/Gwm/GwmModelSelect/GwmModelSelect'
import { LoadingAnimation } from '../../Components/LoadingAnimation/LoadingAnimation'
import { Select } from '../../Components/Select/Select'
import { Arrow } from '../../Images/Arrow'
import { useFetchCarmarketOffersCount } from '../../Shared/Hooks/useFetchCarmarketOffersCount'
import { initialRegistrationOptionsDefault, priceOptions } from '@ac/shared/src/gwm/filterOptions'
import { getQueryStringFromQueryObject } from '../../Shared/helpers/getQueryStringFromQueryObject'
import AutoScout24Logo from './../../Images/AutoScout24Logo.svg'
import AutoScout24Car from './Images/AutoScout24Car.svg'
import ZipRadius, { isZip } from './ZipRadius'

const HAS_NO_VALUE_INT = -1
const HAS_NO_VALUE_STRING = '-1'

export type GwmSearchTeaserInterface = {
    brandsWithModels: Brand[]
    teaserDesignIndexSite: boolean
}

function isEmpty(value: string) {
    return value === '' || value === HAS_NO_VALUE_STRING
}

function getEmptyStringOrValue(value: string) {
    return isEmpty(value) ? '' : value
}

export default function GwmSearchTeaser({ brandsWithModels, teaserDesignIndexSite }: GwmSearchTeaserInterface) {
    const { trackingRef, trackClick } = useTracking({
        event_name: 'affiliate',
        event_data: {
            affiliate_type: 'gwm-suche-teaser',
            affiliate_merchant: 'autoScout24',
        },
    })

    const [isClicked, setIsClicked] = useState(false)
    const [brand, setBrand] = useState<string>(HAS_NO_VALUE_STRING)
    const [model, setModel] = useState<string>(HAS_NO_VALUE_STRING)
    const [zip, setZip] = useState('')
    const [radius, setRadius] = useState('9999')
    const [initialRegistration, setInitialRegistration] = useState<string>(HAS_NO_VALUE_STRING)
    const [priceMax, setPriceMax] = useState<string>(HAS_NO_VALUE_STRING)
    const features = useContext(FeatureContext)

    useAfterMountEffect(() => {
        const params = Array.from(getFilterParameters().entries())
            .filter(([_key, value]) => value !== HAS_NO_VALUE_STRING)
            .reduce((prev: Record<string, string>, [key, value]) => {
                if (key === 'model') {
                    value = model // no prefix
                }
                prev[key] = value
                return prev
            }, {})

        const query = getQueryStringFromQueryObject({
            features: features,
            ...params,
        })
        fetchOfferCount(query)
    }, [features, brand, model, zip, radius, priceMax, initialRegistration])

    function getFilterParameters() {
        const parameters = new URLSearchParams()

        if (!isEmpty(brand)) {
            parameters.set('brand', brand)
        }

        if (!isEmpty(initialRegistration)) {
            parameters.set('initial-registration-from', initialRegistration)
        }

        if (!isEmpty(model)) {
            parameters.set('model', (model.startsWith('L') ? 'L' : 'M') + model)
        }

        if (!isEmpty(priceMax)) {
            parameters.set('price-to', priceMax)
        }

        if (isZip(zip)) {
            parameters.set('zip', zip)
            parameters.set('zip-range', radius)
        }

        return parameters
    }

    function getLinkUrl() {
        const params = getFilterParameters()
        const query = Array.from(params.entries()).length > 0 ? `?${params}` : ''
        const url = new URL(
            `${import.meta.env.VITE_GWM_URL ?? 'https://gebrauchtwagen.autobild.de'}/ergebnisse/${query}`,
        )
        return url.toString()
    }

    function clickHandler() {
        setIsClicked(true)

        trackClick({
            event_data: {
                affiliate_brand: getEmptyStringOrValue(brand),
                affiliate_productname: getEmptyStringOrValue(model),
                affiliate_elementid: getLinkUrl(),
            },
        })
    }

    const { fetchOfferCount, totalNumberOfOffers, totalNumberOfOffersLoading } =
        useFetchCarmarketOffersCount(HAS_NO_VALUE_INT)

    return (
        <div
            className={classNames(
                'relative mx-auto w-full',
                teaserDesignIndexSite && 'mb-36 md:mb-[3.99rem]',
                !teaserDesignIndexSite && 'mb-[3.339rem] md:mx-teaser-xl-aubi md:mb-22',
            )}
            ref={trackingRef}
        >
            {teaserDesignIndexSite && (
                <div className="flex flex-row justify-center md:w-1010 md:items-end">
                    <div
                        className={classNames(
                            'text-24 font-extrabold leading-[3.268rem] text-tarmac-500 md:text-left md:text-28 md:leading-36',
                            'flex w-full flex-col text-center',
                            'mb-3 md:mb-6 md:w-1/2',
                        )}
                        data-testid="separateHeadline"
                    >
                        AUTO BILD Gebrauchtwagenmarkt
                    </div>
                    <div className="hidden md:flex md:w-1/2 md:flex-col md:items-end md:pb-5">
                        <div className="flex items-center justify-end pb-16 text-right leading-28 text-tarmac-200 md:pb-0">
                            <span className="mr-6 text-12">Ein Service von</span>
                            <img
                                className="h-18 w-74 md:h-20 md:w-84"
                                src={asset(AutoScout24Logo)}
                                alt="Ein Service von AutoScout24"
                                loading="lazy"
                                width="84"
                                height="20"
                            />
                        </div>
                    </div>
                </div>
            )}
            <fieldset
                className={classNames(
                    'flex flex-col flex-wrap border-t-1 border-solid border-tarmac-75 font-sans',
                    !teaserDesignIndexSite &&
                        'mb-4 border-x-1 border-b-1 px-12 pb-12 pt-2 md:mb-6 md:px-19 md:pb-20 md:pt-6',
                )}
            >
                {!teaserDesignIndexSite && (
                    <legend className="-ml-6 -mr-5 whitespace-nowrap pl-6 pr-5 text-18 font-bold leading-28 md:-ml-11 md:-mr-9 md:pl-10 md:pr-9 md:uppercase">
                        AUTO BILD Gebrauchtwagenmarkt
                    </legend>
                )}

                <div
                    className={classNames(
                        'bg-tarmac-25 p-12 md:pt-18',
                        teaserDesignIndexSite && 'md:px-20 md:pb-24',
                        !teaserDesignIndexSite && 'md:px-15 md:pb-20',
                    )}
                >
                    <div className="md:flex md:flex-row">
                        <div
                            className={classNames(
                                'mb-24 flex flex-col md:mb-0',
                                teaserDesignIndexSite && 'md:w-712',
                                !teaserDesignIndexSite && 'md:w-682',
                            )}
                        >
                            <div className="mb-10 md:hidden">
                                <img
                                    className="w-230 mx-auto h-auto"
                                    src={asset(AutoScout24Car, { width: 217 })}
                                    alt="Ein Service von AutoScout24"
                                    loading="lazy"
                                    width="217"
                                    height="101"
                                />
                            </div>
                            <p className="mb-10 hidden flex-grow text-left text-23 font-extrabold leading-30 md:inline">
                                Finden Sie jetzt Ihren Top&#8209;Gebrauchtwagen!
                            </p>
                            <div className="flex flex-wrap items-end">
                                <div className="mb-12 w-full md:mb-0 md:w-1/3 md:pr-[1.33rem]">
                                    <GwmBrandSelect
                                        brands={brandsWithModels}
                                        value={brand}
                                        stateDispatcher={(brand) => {
                                            setBrand(brand)
                                            setModel('-1')
                                        }}
                                        data-testid="brandSelect"
                                    />
                                </div>
                                <div className="mb-12 w-full md:mb-0 md:w-1/3 md:pl-[.67rem] md:pr-[.67rem]">
                                    <GwmModelSelect
                                        brand={brand}
                                        brands={brandsWithModels}
                                        value={model}
                                        stateDispatcher={setModel}
                                        data-testid="modelSelect"
                                    />
                                </div>

                                <div className="mb-12 w-1/2 pr-8 md:mb-0 md:w-1/3 md:pl-[1.34rem] md:pr-0">
                                    <Select
                                        label={'Preis bis'}
                                        options={priceOptions}
                                        value={priceMax}
                                        stateDispatcher={setPriceMax}
                                        data-testid="priceToSelect"
                                    />
                                </div>

                                <div className="mb-12 w-1/2 pl-8 md:mb-0 md:w-1/3 md:pl-0 md:pr-[1.33rem]">
                                    <Select
                                        label={'Erstzulassung ab'}
                                        options={initialRegistrationOptionsDefault}
                                        value={initialRegistration}
                                        stateDispatcher={setInitialRegistration}
                                        data-testid="initialRegistrationSelect"
                                    />
                                </div>

                                <ZipRadius
                                    className="gap-x-16 md:mt-6 md:w-2/3 md:gap-x-20 md:pl-[0.67rem]"
                                    zip={zip}
                                    setZip={setZip}
                                    radius={radius}
                                    setRadius={setRadius}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col md:w-258 md:items-center md:justify-end md:pl-20">
                            <div
                                className={classNames(
                                    'hidden w-205 text-center md:mb-15 md:block md:items-center md:justify-center',
                                )}
                            >
                                <img
                                    className="h-auto w-full"
                                    src={asset(AutoScout24Car, { width: 205 })}
                                    alt="Ein Service von AutoScout24"
                                    loading="lazy"
                                    width="205"
                                    height="95"
                                />
                            </div>
                            <a
                                className={classNames(
                                    isClicked ? 'bg-red-500' : 'bg-tarmac-400',
                                    'flex flex-row items-center justify-center',
                                    'border border-solid',
                                    'w-full text-14 font-bold uppercase md:text-18',
                                    'p-13 md:px-15 md:py-14',
                                    'text-white hover:bg-red-light-400',
                                )}
                                onClick={clickHandler}
                                href={getLinkUrl()}
                                data-testid="searchButton"
                            >
                                <span className="mr-9 leading-20 md:mr-12 md:leading-29">
                                    {totalNumberOfOffersLoading || totalNumberOfOffers !== HAS_NO_VALUE_INT ? (
                                        <LoadingAnimation isLoading={totalNumberOfOffersLoading} className="bg-white">
                                            {`${totalNumberOfOffers.toLocaleString('de-DE')} Angebote`}
                                        </LoadingAnimation>
                                    ) : (
                                        <>Angebote finden</>
                                    )}
                                </span>
                                <div className="h-20 w-10 text-14 md:h-29 md:w-15 md:text-18">
                                    <Arrow />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div
                className={classNames(
                    'flex w-full flex-row items-center text-right leading-28 text-tarmac-200 md:w-1010 md:pb-0',
                    teaserDesignIndexSite && 'justify-center bg-tarmac-25 pb-16 pt-2 md:hidden md:pt-0',
                    !teaserDesignIndexSite && 'justify-end',
                )}
            >
                <span className="mr-6 text-12">Ein Service von</span>
                <img
                    className="h-18 w-74 md:h-20 md:w-84"
                    src={asset(AutoScout24Logo)}
                    alt="Ein Service von AutoScout24"
                    loading="lazy"
                    width="84"
                    height="20"
                />
            </div>
        </div>
    )
}
